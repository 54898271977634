body {
  .buorg {
    position: absolute;
    position: fixed;
    z-index: 111111;
    width: 100%;
    top: 0px;
    left: 0px;
    border-bottom: none;
    text-align: center;
    color: #000;
    background-color: #fff;
    font: 16px 'siemens sans', sans-serif;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    animation: 1s ease-out 0s buorgfly;
  }

  .buorg-pad {
    padding: 10px 0;
    line-height: 1.7em;
  }

  .buorg-buttons {
    display: block;
    text-align: center;
    font-size: 14px;
  }

  #buorgig,
  #buorgul,
  #buorgpermanent {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    box-shadow: none;
    padding: 2px 24px;
    border-radius: 8.5em;
    font-weight: 700;
    background: rgba(207, 75, 0, 1);
    white-space: nowrap;
    margin: 6px 4px;
    display: inline-block;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: rgba(236, 102, 2, 1);
    }
  }

  #buorgig {
    background-color: rgba(125, 125, 125, 1);

    &:hover {
      background: rgba(150, 150, 150, 1);
    }
  }

  @media only screen and (max-width: 700px) {
    .buorg div {
      padding: 5px 12px 5px 9px;
      line-height: 1.7em;
    }
  }

  @keyframes buorgfly {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .buorg-fadeout {
    transition: visibility 0s 4.5s, opacity 4s ease-out 0.5s;
  }

  .buorg-icon {
    width: 22px;
    height: 16px;
    vertical-align: middle;
    position: relative;
    top: -0.05em;
    display: inline-block;
    background: no-repeat 0px center;
  }
}
