/* You can add global styles to this file, and also import other style files */
sh-table-head:first-child {
  min-width: 200px;
}

sh-table-cell:first-child {
  min-width: 200px;
}
@import '~@angular/cdk/overlay-prebuilt.css';
sh-table-row,
sh-table-cell,
sh-table-head {
  white-space: nowrap;
}
sh-table {
  overflow-x: auto;
}

sh-table-head:first-child {
  min-width: 350px;
  width: 350px;
}

sh-table-cell:first-child {
  min-width: 350px;
  width: 350px;
}
